const DocMenuConfig = [
  {
    access: [{ system_id: 1, user_level_ids: [1, 2, 3, 4, 5] }],
    heading: '서비스',
    pages: [
      {
        heading: '배송대행',
        route: '/lsf/forwarding-service',
        svgIcon: require('@/media/icons/duotune/abstract/abs017.svg')
      }
    ]
  },
  {
    access: [
      { system_id: 1, user_level_ids: [1, 2, 3] },
      { system_id: 2, user_level_ids: [1, 2, 3] }
    ],
    heading: '시스템 관리',
    pages: [
      {
        heading: '사용자 관리',
        route: '/mgmt/user',
        svgIcon: require('@/media/icons/duotune/communication/com013.svg')
      },
      {
        heading: '센터 관리',
        route: '/mgmt/center',
        svgIcon: require('@/media/icons/duotune/ecommerce/ecm008.svg')
      }
    ]
  },
  {
    access: [{ system_id: 1, user_level_ids: [1, 2, 3, 6, 7] }],
    heading: '평리 워크스페이스',
    pages: [
      {
        sectionTitle: '입하/입고 관리',
        svgIcon: require('@/media/icons/duotune/ecommerce/ecm009.svg'),
        sub: [
          {
            heading: '배송대행 입고',
            route: '/wms/inbound/shipping-service'
          }
        ]
      },
      {
        sectionTitle: '출하 관리',
        svgIcon: require('@/media/icons/duotune/ecommerce/ecm006.svg'),
        sub: [
          {
            heading: '출하대기 BIN 관리',
            route: '/wms/outbound/pda/sort'
          },
          {
            heading: '특송사 출하처리',
            route: '/wms/outbound/pda/send'
          }
        ]
      }
    ]
  },
  {
    access: [{ system_id: 1, user_level_ids: [1, 2, 3, 10, 11] }],
    // access: [{ system_id: 1, user_level_ids: [1, 2, 3, 4, 5] }],
    heading: '클라이언트',
    pages: [
      {
        sectionTitle: '상품 관리',
        svgIcon: require('@/media/icons/duotune/abstract/abs008.svg'),
        sub: [
          {
            heading: '상품 관리',
            route: '/lsf/fulfillment'
          },
          {
            heading: '재고 관리',
            route: '/lsf/stock-mgmt'
          }
        ]
      }
    ]
  },
  {
    access: [{ system_id: 1, user_level_ids: [1, 2, 3, 6, 7, 10, 11] }],
    // access: [{ system_id: 1, user_level_ids: [1, 2, 3, 4, 5] }],
    heading: '클라이언트',
    pages: [
      {
        sectionTitle: '주문 관리',
        svgIcon: require('@/media/icons/duotune/abstract/abs026.svg'),
        sub: [
          {
            heading: '주문 관리',
            route: '/lsf/order-mgmt'
          }
        ]
      }
    ]
  },
  {
    access: [{ system_id: 1, user_level_ids: [1, 2, 3, 6, 7] }],
    heading: '평리 워크스페이스',
    pages: [
      {
        heading: '입고 관리',
        route: '/wms/inbound-mgmt',
        svgIcon: require('@/media/icons/duotune/ecommerce/ecm009.svg')
      },
      {
        heading: '피킹 관리',
        route: '/wms/picking-mgmt',
        svgIcon: require('@/media/icons/duotune/ecommerce/ecm010.svg')
      },
      {
        sectionTitle: '패킹 관리',
        svgIcon: require('@/media/icons/duotune/ecommerce/ecm007.svg'),
        sub: [
          {
            heading: '패킹 대기',
            route: '/wms/packing-mgmt/packing-state'
          },
          {
            heading: '패킹 검수',
            route: '/wms/packing-mgmt/packing-check'
          }
        ]
      }
      // {
      //   sectionTitle: '출고 관리',
      //   route: '/wms/outbound-mgmt',
      //   svgIcon: require('@/media/icons/duotune/ecommerce/ecm006.svg'),
      //   sub: [
      //     {
      //       heading: '출고 작업',
      //       route: '/wms/outbound-mgmt/outbound-process'
      //     },
      //     {
      //       heading: '특송사 인계',
      //       route: '/wms/outbound-mgmt/outbound-parcel'
      //     }
      //   ]
      // },
      // {
      //   sectionTitle: '물류 관리',
      //   route: '/wms/asset-mgmt',
      //   svgIcon: require('@/media/icons/duotune/ecommerce/ecm012.svg'),
      //   sub: [
      //     {
      //       heading: '물류 관리',
      //       route: '/wms/asset-mgmt/forwarding-mgmt'
      //     },
      //     {
      //       heading: '센터 관리',
      //       route: '/wms/asset-mgmt/center-mgmt'
      //     },
      //     {
      //       heading: '사용자 관리',
      //       route: '/wms/asset-mgmt/user-mgmt'
      //     }
      //   ]
      // }
    ]
  }
];

export default DocMenuConfig;
