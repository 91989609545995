import { ElMessage, ElNotification } from 'element-plus';
import { getStaticAsset } from '@/core/helpers/assets';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

async function showAlert(title: string, message: string, type: 'success' | 'warning' | 'info' | 'error' = 'error', show = true, showType: 'message' | 'notification' | 'swal' = 'notification', buttonText = '확인') {
  if (show) {
    // if (['warning', 'info', 'error'].includes(type)) playSfx('check');
    // if (['success'].includes(type)) playSfx('complete');
    if (showType === 'message') {
      ElMessage({ message: message, type: type });
      return true;
    } else if (showType === 'notification') {
      ElNotification({ title: title, message: message, type: type });
      return true;
    } else if (showType === 'swal') {
      let btn_type = 'danger';
      if (type !== 'error') {
        btn_type = type;
      }
      await Swal.fire({
        title: title,
        html: message,
        icon: type,
        buttonsStyling: false,
        confirmButtonText: buttonText,
        customClass: {
          confirmButton: `btn fw-semibold btn-light-${btn_type}`,
          //2024.10.30 ADD 김수호 : 최상단으로 오도록
          container: 'swal-container',
          popup: 'my-swal-popup'
        },
        timer: 2000,
        timerProgressBar: true
      });
      return true;
    }
  }
}

async function showAlertNoTimer(title: string, message: string, type: 'success' | 'warning' | 'info' | 'error' = 'error', show = true, showType: 'message' | 'notification' | 'swal' = 'notification', buttonText = '확인') {
  if (show) {
    if (showType === 'message') {
      ElMessage({ message: message, type: type });
      return true;
    } else if (showType === 'notification') {
      ElNotification({ title: title, message: message, type: type });
      return true;
    } else if (showType === 'swal') {
      let btn_type = 'danger';
      if (type !== 'error') {
        btn_type = type;
      }
      await Swal.fire({
        title: title,
        html: message,
        icon: type,
        buttonsStyling: false,
        confirmButtonText: buttonText,
        customClass: {
          confirmButton: `btn fw-semibold btn-light-${btn_type}`,
          container: 'swal-container',
          popup: 'my-swal-popup'
        }
      });
      return true;
    }
  }
}

function playSfx(action: 'success' | 'error' | 'check' | 'undo' | 'complete' | 'loaded' = 'error') {
  new Audio(getStaticAsset(`sounds/${action}.mp3`)).play();
}

async function userConfirm(object: { title: string; html: string; color: string; confirm: string; icon: string }) {
  const { isConfirmed } = await Swal.fire({
    title: object.title,
    html: object.html,
    icon: object.icon,
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: object.confirm,
    cancelButtonText: '아니요',
    customClass: {
      confirmButton: `btn fw-semibold btn-${object.color}`,
      cancelButton: 'btn fw-semibold btn-light',
      //2024.10.30 ADD 김수호 : 최상단으로 오도록
      container: 'swal-container',
      popup: 'my-swal-popup'
    }
  });
  return isConfirmed;
}

async function orderCancel(message: string) {
  const { isConfirmed } = await Swal.fire({
    title: '배송건 영구 취소 확인',
    html: `취소된 주문(배송)건은 <strong>복구가 불가능합니다</strong>.<br>${message}`,
    icon: 'error',
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: '네, 영구 취소하겠습니다',
    cancelButtonText: '아니요',
    customClass: {
      confirmButton: 'btn fw-semibold btn-light',
      cancelButton: 'btn fw-semibold btn-danger',
      //2024.10.30 ADD 김수호 : 최상단으로 오도록
      container: 'swal-container',
      popup: 'my-swal-popup'
    }
  });
  return isConfirmed;
}

export default { showAlert, userConfirm, orderCancel, showAlertNoTimer };
export { playSfx, Swal };
