import store from '@/store';
import { Clients } from '@/core/api';
import { LsfService, WmsService } from '@/core/services/ApiService';
import type { Address } from '@/core/interfaces';
import AssetSkuObject from '@/core/entity/AssetSku';

interface OrderSku {
  brand_name: string;
  client_code: string;
  currency: string;
  hs_code: string;
  name: string;
  option1: string | null;
  option2?: string | null;
  option3?: string | null;
  option4?: string | null;
  option5?: string | null;
  unit_price: number;
  quantity: number;
  size: string;
  thumbnail_url: string;
  type: string;
  unique_code: string;
  url: string;
  vendor_name: string;
  //2024.06.20 ADD 김수호
  item_code: string; //ACI용 품목코드
  native_item_detail: string; //상품 현지 이름
}

export interface ForwardingOrderForm {
  client_code: string;
  domestic_courier_id: number;
  fc_id: number;
  order_number: string;
  order_shipping: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    first_name: string;
    last_name: string;
    mobile: string;
    postal: string;
    province: string;
  };
  order_skus: OrderSku[];
  started_delivery_at: string;
}

export class OrderSkuObject implements OrderSku {
  brand_name: string;
  client_code: string;
  currency: string;
  hs_code: string;
  name: string;
  option1: string | null;
  option2?: string | null;
  option3?: string | null;
  option4?: string | null;
  option5?: string | null;
  unit_price: number;
  quantity: number;
  size: string;
  thumbnail_url: string;
  type: string;
  unique_code: string;
  url: string;
  vendor_name: string;
  //2024.06.20 ADD 김수호
  item_code: string; //ACI용 품목코드
  native_item_detail: string; //상품 현지 이름

  constructor(object: AssetSkuObject) {
    this.brand_name = object.brand_name;
    this.hs_code = object.hs_code;
    this.name = object.name;
    this.option1 = object.option1;
    this.option2 = object.option2;
    this.option3 = object.option3;
    this.option4 = object.option4;
    this.option5 = object.option5;
    this.size = object.size;
    this.thumbnail_url = object.thumbnail_url;
    this.type = object.type;
    this.unique_code = object.unique_code;
    this.url = object.url;
    this.vendor_name = object.vendor_name;
    this.client_code = '';
    this.currency = 'KR';
    this.unit_price = object.unit_price;
    this.quantity = 0;
    //2024.06.20 ADD 김수호
    this.item_code = object.item_code;
    this.native_item_detail = object.native_item_detail; //상품 현지 이름 (ACI 일본 필수)
  }

  optionString() {
    return [this.option1, this.option2, this.option3, this.option4, this.option5].filter((option) => option !== null).join(' / ');
  }
}

export interface FulfillmentOrderForm {
  order_number: string;
  client_code: string;
  ordered_at: string;
  order_skus: {
    brand_name: string;
    client_code: string;
    currency: string;
    unit_price: number;
    quantity: number;
    unique_code: string;
    vendor_name: string;
    name: string;
    native_item_detail: string;
  }[];
  order_shipping: {
    address1: string;
    address2: string | null;
    city: string;
    country: string;
    email: string | null;
    first_name: string;
    last_name: string;
    mobile: string;
    postal: string;
    province: string;
    tel: string | null;
  };
  order_customer: {
    first_name: string;
    last_name: string;
    mobile: string;
    country: string;
    province: string;
    city: string;
    address1: string;
    address2: string | null;
    postal: string;
  };
}

export interface UpdateOrderFulfillmentStatusForm {
  order_fulfillment_state?: string;
  wms_order_fulfillment_status?: string;
}

// export interface OrderSkus {
//   asset_id: number;
//   quantity: number;
// }

export interface DeleteFulfillmentOrder {
  order_id: number;
  delete_skus: {
    asset_id: number;
    unique_code: string;
    quantity: number;
  }[];
  order_skus: {
    client_code: string;
    quantity: number;
    unique_code: string;
  }[];
}

async function updateOrderFufillment(of_id: number, form: UpdateOrderFulfillmentStatusForm) {
  return await LsfService.put(`order-fulfillment/${of_id}`, form);
}

async function getForwardingOrder(page: number, of_statuses: string[], client_ids: number[] = [], search_value: string | null = null, startDate: string | null = null, endDate: string | null = null, search_type = 'ORDER_NUMBER', size = 50) {
  return await LsfService.query(`orders/forwarding/${page}`, {
    params: {
      size: size,
      'of-statuses': of_statuses.join(','),
      'search-type': search_type,
      'search-value': search_value,
      'client-ids': client_ids.join(','),
      'start-search-date': startDate,
      'end-search-date': endDate
    }
  });
}

async function getOrderFulfilllmentCountByStatus(clientIds: number[] = []) {
  if (clientIds.length === 0) {
    const { success, data: clients } = await Clients.getClients(1);
    if (success && store.getters.userClient.id === 1) {
      clientIds = clients.map((c) => c.client_id);
    } else {
      clientIds = [store.getters.userClient.id];
    }
  }
  return await LsfService.query('order-fulfillment/status/count', { params: { 'client-ids': clientIds.join(',') } });
}

async function getOrderCountByStatus(clientIds: number[] = []) {
  if (clientIds.length === 0) {
    const { success, data: clients } = await Clients.getClients(1);
    if (success && store.getters.userClient.id === 1) {
      clientIds = clients.map((c) => c.client_id);
    } else {
      clientIds = [store.getters.userClient.id];
    }
  }
  return await LsfService.query('external/order/status/count', { params: { 'client-ids': clientIds.join(',') } });
}

export interface UpdateOrderSkuForm {
  currency: string;
  sku_name: string; // different from Swagger
  unit_price: number;
  quantity: number;
}

async function updateForwardingOrder(orderSkuId: number, form: UpdateOrderSkuForm, admin = false) {
  if (admin) {
    return await LsfService.put(`admin/order/forwarding/order-sku/${orderSkuId}`, form);
  } else {
    return await LsfService.put(`order/forwarding/order-sku/${orderSkuId}`, form);
  }
}

async function cancelOrderFulfillment(orderFulfillmentId: number) {
  return await LsfService.delete(`order-fulfillment/cancellation/${orderFulfillmentId}`);
}

async function holdingOrderFulfillment(orderFulfillmentId: number, state: boolean) {
  return await LsfService.put(`order-fulfillment/holding/${orderFulfillmentId}`, { holding: state });
}

async function updateOrderShipping(orderShippingId: number, addressForm: Address, admin = false) {
  if (admin) {
    return await LsfService.put(`admin/order/shipping/${orderShippingId}`, addressForm);
  } else {
    return await LsfService.put(`order/shipping/${orderShippingId}`, addressForm);
  }
}

async function forwardingOrder(form: ForwardingOrderForm) {
  return await LsfService.post('order/forwarding', form);
}

async function fulfillmentOrder(form: FulfillmentOrderForm) {
  return await LsfService.post('external/order/client', form);
}

async function deleteFulfillmentOrderSkus(form: DeleteFulfillmentOrder) {
  return await LsfService.post('external/delete-order-sku/client', form);
}

async function deleteFulfillmentOrder(form: DeleteFulfillmentOrder) {
  return await LsfService.post('external/delete-order/client', form);
}

//2024.08.26 CREATE 김수호 : 주문 등록 전 재고량 확인
async function getStocks(form: FulfillmentOrderForm) {
  const params = {
    clients_id: store.getters.userClient?.id, //this.clientIds.join(','),
    'search-values': form.order_skus.map((s) => `${s.unique_code}`).join(','),
    'inventory-type-id': 2,
    'system-id': 1
  };
  return await WmsService.query(`asset/skus/searchStocks`, { params: params });
}

async function swappingOrder(form: Array<number>) {
  return await WmsService.post('order-fulfillment/changeToPickingReady', form);
}

export default {
  updateOrderFufillment,
  getForwardingOrder,
  getOrderFulfilllmentCountByStatus,
  getOrderCountByStatus,
  updateForwardingOrder,
  cancelOrderFulfillment,
  holdingOrderFulfillment,
  updateOrderShipping,
  forwardingOrder,
  fulfillmentOrder,
  deleteFulfillmentOrderSkus,
  deleteFulfillmentOrder,
  getStocks,
  swappingOrder
};
