import { LsfService } from '@/core/services/ApiService';
import type { Option } from '@/core/interfaces';
import store from '@/store';

interface Client {
  id: number;
  code: string;
  name: string;
  active: boolean;
}

class ClientObject implements Client {
  id: number;
  code: string;
  name: string;
  active: boolean;

  constructor(object: Client) {
    this.id = object.id;
    this.code = object.code;
    this.name = object.name;
    this.active = object.active;
  }

  async addUser(userId: number) {
    const response = await LsfService.put(`client/${this.id}/join/${userId}`, {});
    if (response.success) {
      return response;
    } else {
      console.warn(`Failed to Add User ${userId} - Server Error Response (${response.code}).`);
    }
  }
}

class Clients {
  clients: Client[];
  options: Option[];

  constructor() {
    this.clients = [];
    this.options = [];
  }

  async load() {
    const response = await LsfService.query('clients', {});
    if (response.success) {
      this.clients = response.data.map((c) => new ClientObject({ id: c.client_id, code: c.code, name: c.name, active: c.active }));
      this.generateOptions();
    } else if (response.code === 6005) {
      const user = store.getters.userClient;
      this.clients = [new ClientObject({ id: user.id, code: user.code, name: user.name, active: true })];
      this.generateOptions();
    } else {
      console.warn(`Failed to Load Clients - Server Error Response (${response.code}).`);
    }
  }

  generateOptions(): void {
    this.options = this.clients.map((client) => ({ label: client.name, value: client.id }));
  }
}

export { ClientObject, Clients };
export default Clients;
