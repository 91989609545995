import { WmsService } from '@/core/services/ApiService';

interface CreateSkuForm {
  brand_name: string | null;
  client_code: string;
  hs_code: string;
  name: string;
  option1: string;
  option2?: string | null;
  option3?: string | null;
  option4?: string | null;
  option5?: string | null;
  size: string | null;
  system_id: number;
  thumbnail_url: string;
  translations: [
    {
      lang: string;
      name: string;
      option1: string | null;
      option2: string | null;
      option3: string | null;
      option4: string | null;
      option5: string | null;
    }
  ];
  type: string | null;
  unique_code: string;
  url: string;
  vendor_name: string | null;
  product_barcode: string; //2024.07.25 김수호
  jp_customs_yn: number; //2024.08.16 김수호
  native_item_detail: string; //2024.08.16 김수호
  unit_price: number; //2024.09.26 김수호
}

interface UpdateSkuForm {
  name: string;
  option1: string | null;
  option2: string | null;
  option3: string | null;
  option4: string | null;
  option5: string | null;
  thumbnail_url: string;
  translations: [
    {
      lang: string;
      name: string;
      option1: string | null;
      option2: string | null;
      option3: string | null;
      option4: string | null;
      option5: string | null;
    }
  ];
  url: string;
  product_barcode: string; //2024.07.25 김수호 : 상품바코드 추가
  jp_customs_yn: number; //2024.08.16 김수호
  native_item_detail: string; //2024.08.16 김수호
  unit_price: number; //2024.09.26 김수호
}

async function createSkuAsset(form: CreateSkuForm) {
  return await WmsService.post('asset/sku', form);
}

async function updateSkuAsset(sku_id: number, system_id: number, client_id: number, form: UpdateSkuForm) {
  return await WmsService.put(`asset/sku/${sku_id}?system-id=${system_id}&client-id=${client_id}`, form);
}

//2024.08.16 CREATE 김수호 : 일본 통관 품목코드 생성을 위해 가 데이터로 ACI 배송 등록 후 삭제 처리하는 API
async function createAciTempShipment(form: CreateSkuForm) {
  return await WmsService.post('asset/sku/createJapanItemCode', form);
}

export { CreateSkuForm, UpdateSkuForm, createSkuAsset, updateSkuAsset, createAciTempShipment };
export default {
  createSkuAsset,
  updateSkuAsset,
  createAciTempShipment
};
