import { WmsService } from '@/core/services/ApiService';

// Basic Asset
async function getAsset(asset_id: number) {
  return await WmsService.query(`asset/${asset_id}`, {
    params: { 'system-id': 1 }
  });
}

// Inbound Parcel
async function getInboundParcel(trackingNumber: string) {
  return await WmsService.query(`asset/inbound-parcel/${trackingNumber}`, {
    params: {
      'domestic-courier-id': 1,
      'system-id': 1
    }
  });
}

// Outbound Parcel
async function getOutboundParcel(of_id: number) {
  return await WmsService.get('asset/outbound-parcel/order-fulfillment', `${of_id}`);
}

async function getOutboundParcelByTrackingNumber(tracking_number: string) {
  return await WmsService.query(`asset/outbound-parcel/order-fulfillment/tracking-number/${tracking_number}`, {});
}

//2024.09.25 CREATE 김수호 : 가송장번호로 송장번호를 조회
async function getTrackingNumberByInternalTrackingNumber(intenalTracking_number: string) {
  return await WmsService.query(`asset/outbound-parcel/order-fulfillment/internal-tracking-number/${intenalTracking_number}`, {});
}

//2024.09.25 CREATE 김수호 : 가송장번호로 송장번호를 조회
async function getAllTrackingNumber() {
  return await WmsService.query(`asset/outbound-parcel/order-fulfillment/all-internal-tracking-number`, {});
}

async function getPackingDoneOutboundParcelCountByCourier(fc_id: number) {
  return await WmsService.query(`asset/outbound-parcel/courier/count?type=PACKING_DONE`, {
    params: { 'fc-id': fc_id }
  });
}

async function getPackingDoneOutboundParcelsByCourier(fc_id: number, courier_id: number, page = 1, size = 50) {
  return await WmsService.query(`asset/outbound-parcel/courier/${page}?type=PACKING_DONE`, {
    params: {
      'fc-id': fc_id,
      'courier-id': courier_id,
      size: size
    }
  });
}

async function getSkuByAssetId(asset_id: number) {
  return await WmsService.query(`asset/sku/${asset_id}`, {
    params: {
      'system-id': 1
    }
  });
}

export interface UpdateSkuForm {
  name: string;
  hs_code: string;
  stock_size: 'UNKNOWN' | 'SMALL' | 'MEDIUM' | 'LARGE';
  stock_type: 'UNKNOWN' | 'NORMAL' | 'FRESH' | 'FROZEN';
}

async function updateSku(asset_id: number, form: UpdateSkuForm) {
  return await WmsService.put(`asset/sku/${asset_id}`, form);
}

export default {
  getAsset,
  getInboundParcel,
  getOutboundParcel,
  getOutboundParcelByTrackingNumber,
  getTrackingNumberByInternalTrackingNumber,
  getAllTrackingNumber,
  getPackingDoneOutboundParcelCountByCourier,
  getPackingDoneOutboundParcelsByCourier,
  getSkuByAssetId,
  updateSku
};
